import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'
import facebookPixel from './plugins/facebookPixel'

document.addEventListener('DOMContentLoaded', async () => {
  const googleAnalyticsId = document.querySelector(
    'meta[name="google-analytics-id"]'
  )?.content
  const facebookPixelId = document.querySelector(
    'meta[name="facebook-pixel-id"]'
  )?.content
  const tagManagerId = document.querySelector('meta[name="tag-manager-key"]')
    ?.content

  let plugins = []

  if (googleAnalyticsId) {
    plugins.push(
      googleAnalytics({
        measurementIds: [googleAnalyticsId],
      })
    )
  }

  if (tagManagerId) {
    plugins.push(googleTagManager({ containerId: googleTagManager }))
  }

  if (facebookPixelId) {
    plugins.push(facebookPixel({ trackingId: facebookPixelId }))
  }

  const analytics = Analytics({
    app: 'Clearworks',
    plugins,
  })

  analytics.page()
  window.analytics = analytics

  function getGaClientId() {
    const cookies = document.cookie.split(';').reduce((acc, cookie) => {
      const [key, value] = cookie.split('=').map(c => c.trim())
      acc[key] = value
      return acc
    }, {})

    return cookies['_ga'] ? cookies['_ga'].substring(6) : ''
  }

  const clientIdInput = document.getElementById('hidden-google-client-id')
  if (clientIdInput) {
    clientIdInput.value = getGaClientId()
  }
})
